import React from 'react';
import {inject, observer} from "mobx-react";
import RootStore from "../../Stores/RootStore";
import "./MainRoute.scss"
import {CSSTransition} from "react-transition-group";
import MainTitle from "./MainTitle";
import Links from "./Components/Links";

@inject("RootStore")
@observer
class MainRoute extends React.Component<{ RootStore?: RootStore, key?: any, id?: any }, any> {

	componentDidMount(): void {
		document.title = "Привет";
		setTimeout(this.props.RootStore!.MainRouteStore.setDisplayMainTitle.bind(null, true), 1000)
	}

	componentWillUnmount(): void {
		let MRStore = this.props.RootStore!.MainRouteStore;
		MRStore.setBounce(-1);
		MRStore.setTitleDefaultColor();
	}

	render() {
		const MRStore = this.props.RootStore!.MainRouteStore;

		return (
			<div id="MainRoute">
				<CSSTransition
					unmountOnExit
					in={MRStore.displayMainTitle}
					timeout={3000}
					classNames={'my-node'}
				>
					<div style={{flexDirection: "column", width: "100%"}}>
						<div	 style={{height: "85vh"}}>
							<MainTitle/>
						</div>
						<Links/>
					</div>
				</CSSTransition>
			</div>
		);
	}
}

export default MainRoute;
