import {inject, observer} from "mobx-react";
import React from "react";
import RootStore from "../../Stores/RootStore";

@inject("RootStore")
@observer
class MainTitle extends React.Component<{ RootStore?: RootStore, key?: any, id?: any }, any> {
    render() {
        const MRStore = this.props.RootStore!.MainRouteStore;
        return <div className='main_title' key='main_title'>
            {MRStore.mainTitle.map((item, i) => {
                    const isBounce = MRStore.bouncingLetterNum === i;
                    const className = (isBounce) ? 'letter letter_bounce' : 'letter';
                    const onClick = (i !== 0 && i !== MRStore.mainTitle.length - 1)
                        ? MRStore.letterClick.bind(null, item)
                        : undefined;
                    return (
                        <span
                            className={className}
                            key={i}
                            style={{color: MRStore.getCSScolor(item.color)}}
                            onClick={onClick}
                        >
                {item.letter}
              </span>
                    )
                }
            )}
        </div>;
    }
}
export default MainTitle