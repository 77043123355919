import {inject, observer} from "mobx-react";
import React from "react";
import RootStore from "../../../Stores/RootStore";
import "./Links.scss"

type Props = { RootStore?: RootStore, key?: any, id?: any }

@inject("RootStore")
@observer
export default class Links extends React.Component<Props, { windowWidth: number }> {
    constructor(props: Props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth
        }
    }
    componentDidMount() {
        this.props.RootStore?.MainRouteStore.fetchLinks()
        setInterval(()=>{
            this.setState({windowWidth: window.innerWidth})
        },1000)
    }

    getGridWidth(links: ILink[], columnsWidths: Record<number, number>): number {
        const fromLinks = links.length ? links[0].maxColumns : 1
        const mediaWidths: number[] = Object.keys(columnsWidths).map(parseInt);
        const mediaWidth = this.tryFindNearestHigherMediaWidth(mediaWidths)
        if(mediaWidth){
            return columnsWidths[mediaWidth] || 1
        } else {
            return fromLinks
        }
    }

    private tryFindNearestHigherMediaWidth(mediaWidths: number[]): number|null {
        mediaWidths = mediaWidths.sort()
        for (const mediaWidth of mediaWidths){
            if(mediaWidth > this.state.windowWidth) return mediaWidth;
        }
        return null;
    }

    render() {
        const kostyl = this.state.windowWidth;
        const links = this.props.RootStore?.MainRouteStore.links || []
        const columnsWidths = this.props.RootStore?.MainRouteStore.columnsWidths || {}

        const gridWidth = this.getGridWidth(links, columnsWidths)
        const cellWidthPercents = 100/gridWidth;
        return <div id={"Links"} style={{gridTemplateColumns: `repeat(${gridWidth}, ${cellWidthPercents}%)`}}>
            {links.map(link=>{
                const linkSize = this.getColumnsAndRows(link)
                const aspectRatio = `${linkSize.columns}/${linkSize.rows}`
                return <div key={link.id} style={{gridColumn: `span ${linkSize.columns}`, gridRow: `span ${linkSize.rows}`, aspectRatio}}>
                    <a href={link.href} target={"_blank"}><div dangerouslySetInnerHTML={{__html: link.html}}/></a>
                </div>
            })}
        </div>;
    }


    private getColumnsAndRows(link: ILink): { rows: number, columns: number } {
        const mediaWidth = this.tryFindNearestHigherMediaWidth(Object.keys(link.sizes).map(parseInt));
        if(!mediaWidth){
            return {rows: link.rows, columns: link.columns}
        }
        const size = link.sizes[mediaWidth];
        return {rows: size.row, columns: size.column}
    }
}